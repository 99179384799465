import { EventEmitter, Injectable } from '@angular/core';
import { DrawerType } from '../../models/drawer.type';
import { Notification } from '../../models/notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private drawerType: DrawerType = 'auto';
  private effectiveDrawerType: DrawerType = 'push';
  public notifications: Notification[] = [];

  public notificationRecieved: EventEmitter<any> = new EventEmitter<any>();
  public drawerTypeChanged: EventEmitter<DrawerType> = new EventEmitter<DrawerType>();

  constructor() {
    // window.addEventListener('resize', (event: Event) => {
    //   if (this.drawerType === 'auto') {
    //     const target: Window = event.target as Window;
    //     const screenWidth: number = target.innerWidth;
    //     this.calculateAutoType(screenWidth);
    //   }
    // });

    // this.setPastNotifications(20);
    // this.newNotification();
  }

  // setPastNotifications(limit: number): any {
  //   for (let index = 0; index < limit; index += 1) {
  //     const title = index % 2 === 0 ? 'Nouvelle demande de consultation de la part de ' : 'Annulation de consultation de';
  //     const icon = index % 2 === 0 ? 'farCalendar' : 'fasExclamation';
  //     this.notifications.push(new Notification(index.toString(), `${title} 'Julie Dupont'`, new Date(), icon));
  //   }
  // }

  // newNotification() {
  //   const title = Math.floor(Math.random() * 2) ? 'Nouvelle demande de consultation de la part de ' : 'Annulation de consultation de';
  //   const icon = Math.floor(Math.random() * 2) ? 'farCalendar' : 'fasExclamation';
  //   this.notifications.push(new Notification(((this.notifications.length * 1000) + 1).toString(), `${title} 'Julie Dupont'`, new Date(), icon));

  //   this.notificationRecieved.emit(this.notifications);
  // }

  setDrawerType(type: DrawerType) {
    this.drawerType = type;
    if (type !== 'auto') {
      this.effectiveDrawerType = type;
      this.drawerTypeChanged.emit(this.effectiveDrawerType);
      return;
    }

    this.calculateAutoType(window.screen.width);
  }

  calculateAutoType(width: number) {
    let newType: DrawerType = 'over';
    if (width > 1400) {
      newType = 'push';
    }
    if (newType !== this.effectiveDrawerType) {
      this.effectiveDrawerType = newType;
      this.drawerTypeChanged.emit(this.effectiveDrawerType);
    }
  }

  getDrawerType() {
    return this.drawerType;
  }
}
